var baseStyle = {
  borderRadius: 'radii.1',
  border: 'muted'
};
var appearances = {};
var sizes = {};
export default {
  baseStyle: baseStyle,
  appearances: appearances,
  sizes: sizes
};